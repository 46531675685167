// src/components/Footer.js
import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: "grey.100", py: 6, mt: 4 }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              color="text.secondary"
              align="center"
              mb={2}
            >
              주식회사 치킨리스트27
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              고객센터 : 010-7392-7127 | 창업문의 : 010-7392-7127
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              대표자 : 최수진 | 경기도 고양시 덕양구 청초로66 리버워크 905호
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              사업자등록번호 : 767-88-02981
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          mt={2}
        >
          © {new Date().getFullYear()} CL27 All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
