import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function EventDetail() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/db/event.json`)
      .then((response) => response.json())
      .then((data) => {
        const eventDetail = data.events.find((event) => event.id === eventId);
        setEvent(eventDetail);
      });
  }, [eventId]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // 이미지의 최대 너비를 자연스럽게 선형으로 조절
  const maxImageWidth = Math.min(0.8 * windowWidth, 600);

  return event ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      textAlign="center"
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{ mt: 8, mb: 1 }}
      >
        {event.title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {`이벤트 기간 : ${event.event_start} ~ ${event.event_end}`}
      </Typography>

      <CardMedia
        component="img"
        image={`${process.env.PUBLIC_URL}/assets/images/event/${event.src}`}
        alt={event.title}
        style={{ maxWidth: maxImageWidth }}
      />

      <Link to="/events" style={{ textDecoration: "none", marginTop: "20px" }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          sx={{
            color: "black",
            bgcolor: "white",
            mb: 6,
            "&:hover": {
              bgcolor: "grey",
            },
          }}
        >
          목록으로 돌아가기
        </Button>
      </Link>
    </Box>
  ) : (
    <Typography variant="h6" textAlign="center"></Typography>
  );
}

export default EventDetail;
