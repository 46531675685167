import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CardMedia,
  IconButton,
  Container,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function Main() {
  const [data, setData] = useState({ images: [] });
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/db/home.json`)
      .then((response) => response.json())
      .then(setData);
  }, []);

  const maxSteps = data.images.length;

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  }, [maxSteps]);

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep + maxSteps - 1) % maxSteps
    );
  };

  const handleStepClick = () => {
    if (data.images[activeStep]) {
      navigate(data.images[activeStep].link);
    }
  };

  useEffect(() => {
    const timer = setInterval(handleNext, 3000);
    return () => clearInterval(timer);
  }, [activeStep, maxSteps, handleNext]);

  return (
    <Box
      sx={{
        opacity: 0.9,
        borderTop: 1,
        borderBottom: 1,
        borderColor: "grey.200",
        ".dark &": {
          bgcolor: "grey.900",
          borderColor: "grey.800",
        },
      }}
      data-id="17"
    >
      <Container
        sx={{ gap: 4, px: 4, py: { xs: 12, md: 24, lg: 32 }, display: "grid" }}
        data-id="18"
      >
        <Box
          className="grid gap-4 items-center justify-center text-center"
          data-id="19"
        >
          <Box className="space-y-2" data-id="20">
            <Typography
              variant="h2"
              component="h1"
              sx={{
                mx: "auto",
                maxWidth: 600,
                textAlign: "center",
                fontSize: { xs: "2.25rem", sm: "3rem", md: "3.75rem" },
                fontWeight: 900,
                tracking: "tighter",
              }}
              data-id="1"
              mt={2}
              mb={2}
            >
              치킨 리스트 27
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mx: "auto",
                maxWidth: 600,
                color: "text.secondary",
                textAlign: "center",
                fontSize: { md: "1rem", lg: "1.125rem", xl: "1.25rem" },
              }}
              data-id="2"
              mt={2}
              mb={6}
            >
              세상에서 가장 맛있는 치킨과 27가지 메뉴를 확인하세요
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={handleBack} sx={{ color: "black" }}>
              <ArrowBackIosNewIcon />
            </IconButton>
            {data.images.length > 0 && (
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/assets/images/home/${data.images[activeStep].src}`}
                alt={`Slide ${activeStep}`}
                onClick={handleStepClick}
                sx={{
                  borderRadius: "lg",
                  height: 300,
                  maxWidth: 600,
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            )}
            <IconButton onClick={handleNext} sx={{ color: "black" }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Main;
