import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CardMedia,
  Container,
  Grid,
  Tab,
  Tabs,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";

function Menu() {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/db/menu.json`)
      .then((response) => response.json())
      .then((data) => setMenuItems(data.menus));
  }, []);

  const menuTypes = [...new Set(menuItems.map((item) => item.type))];

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderMenuItemName = (name) => {
    return name.split(/(\(앤\))/).map((part, index) =>
      part === "(앤)" ? (
        <span
          key={index}
          style={{ fontSize: "75%", verticalAlign: "baseline" }}
        >
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4, mb: 8 }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="standard"
          centered
          textColor="inherit"
          indicatorColor="primary"
        >
          {menuTypes.map((type, index) => (
            <Tab
              key={index}
              label={type}
              sx={{ fontSize: "large", fontWeight: 900 }}
            />
          ))}
        </Tabs>
      </Box>
      {menuTypes.map((type, index) => (
        <Box
          key={index}
          sx={{ display: selectedTab === index ? "block" : "none" }}
        >
          <Typography
            variant="h3"
            sx={{ my: 2, textAlign: "center", fontWeight: 700, mb: 10 }}
          >
            {type}
          </Typography>
          <Grid container spacing={3}>
            {menuItems
              .filter((item) => item.type === type)
              .map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    elevation={4}
                    onMouseEnter={() => setHoverIndex(index)}
                    onMouseLeave={() => setHoverIndex(null)}
                    sx={{ position: "relative" }}
                  >
                    <CardActionArea sx={{ height: "100%" }}>
                      <Box sx={{ position: "relative" }}>
                        <CardMedia
                          component="img"
                          image={`${process.env.PUBLIC_URL}/assets/images/menu/${item.src}`}
                          alt={item.name}
                          sx={{ height: 300 }}
                        />
                        {hoverIndex === index && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              p: 2,
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={`${process.env.PUBLIC_URL}/assets/images/frame/black.png`}
                              alt="overlay"
                              sx={{
                                width: "100%",
                                height: "100%",
                                objectFit: "fill",
                                opacity: 0.8,
                                position: "absolute",
                              }}
                            />
                            <Box sx={{ position: "relative", zIndex: 2 }}>
                              <Typography
                                variant="body1"
                                sx={{
                                  mb: 1,
                                  fontWeight: 900,
                                  whiteSpace: "pre-line",
                                  fontSize: "0.9rem",
                                  textAlign: "center",
                                }}
                              >
                                {item.description
                                  .split("&& ")
                                  .map((line, index) => (
                                    <React.Fragment key={index}>
                                      {index > 0 && "\n"}
                                      {line}
                                    </React.Fragment>
                                  ))}
                              </Typography>

                              <Typography
                                variant="body1"
                                sx={{
                                  mb: 3,
                                  fontWeight: 900,
                                  whiteSpace: "pre-line",
                                  fontSize: "0.9rem",
                                  textAlign: "center",
                                }}
                              >
                                {item.origin && `원산지 : ${item.origin}`}
                              </Typography>
                              {Object.entries(item.units).map(
                                ([key, value]) => (
                                  <Typography
                                    key={key}
                                    variant="body2"
                                    sx={{
                                      fontSize: "0.9rem",
                                      fontWeight: 900,
                                      textAlign: "center",
                                    }}
                                  >
                                    {value}: {item.prices[key]} 원
                                  </Typography>
                                )
                              )}
                            </Box>
                          </Box>
                        )}
                      </Box>
                      <CardContent>
                        <Typography
                          variant="h5"
                          sx={{ fontWeight: 900, textAlign: "center" }}
                        >
                          {renderMenuItemName(item.name)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      ))}
    </Container>
  );
}

export default Menu;
