import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResponsiveAppBar from "./components/Navigation";
import Footer from "./components/Footer";

import Main from "./pages/Main";
import Brand from "./pages/Brand"
import Menu from "./pages/Menu";
import Places from "./pages/Places";
import Events from "./pages/Events";
import EventDetail from "./pages/EventDetail";

// Pretendard 폰트를 적용한 테마 생성
const theme = createTheme({
  typography: {
    fontFamily: "Pretendard, Arial",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/brand" element={<Brand />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/places" element={<Places />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:eventId" element={<EventDetail />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
