import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Pagination from "@mui/material/Pagination";

function Events() {
  const [tabValue, setTabValue] = useState("ongoing");
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const eventsPerPage = 5;

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/db/event.json`)
      .then((response) => response.json())
      .then((data) => setEvents(data.events));
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); // 탭 변경 시 페이지를 1로 설정
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const filteredEvents = events.filter((event) => {
    const today = new Date();
    const eventStart = new Date(event.event_start);
    const eventEnd = new Date(event.event_end);
    return tabValue === "ongoing"
      ? today >= eventStart && today <= eventEnd
      : today > eventEnd;
  });

  const paginatedEvents = filteredEvents.slice(
    (page - 1) * eventsPerPage,
    page * eventsPerPage
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4, mb: 8 }}>
        <Tabs
          value={tabValue}
          centered
          onChange={handleChange}
          aria-label="event tabs"
          textColor="inherit"
        >
          <Tab
            value="ongoing"
            label="진행중인 이벤트"
            sx={{ fontSize: "large", fontWeight: 900 }}
          />
          <Tab
            value="completed"
            label="완료된 이벤트"
            sx={{ fontSize: "large", fontWeight: 900 }}
          />
        </Tabs>
      </Box>
      {paginatedEvents.map((event, index) => (
        <Link
          to={`/events/${event.id}`}
          key={index}
          style={{ textDecoration: "none" }}
        >
          <Card sx={{ m: 2 }}>
            <CardMedia
              component="img"
              image={`${process.env.PUBLIC_URL}/assets/images/event/${event.src_thumnail}`}
              alt={event.title}
              sx={{
                width: "100%", // 부모 요소에 맞게 가로 크기 조정
                height: "auto", // 세로 크기를 자동으로 조정하여 가로와의 비율 유지
                objectFit: "cover",
              }}
            />
          </Card>
        </Link>
      ))}
      <Pagination
        count={Math.ceil(filteredEvents.length / eventsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ marginY: 5 }}
      />
    </Box>
  );
}

export default Events;
