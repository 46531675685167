import React, { useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';

function Brand() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabContent = [
    {
      label: '대표 인사말',
      content: '대표 인사말 내용이 여기에 표시됩니다.',
    },
    {
      label: 'CL27 소개',
      content: 'CL27 소개 내용이 여기에 표시됩니다.',
    },
    {
      label: 'CL27 전문성',
      content: 'CL27의 전문성에 대한 내용이 여기에 표시됩니다.',
    },
    {
      label: 'CL27 컨셉',
      content: 'CL27의 컨셉에 대한 설명이 여기에 표시됩니다.',
    },
  ];

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4, mb: 8 }}>
        <Tabs
          value={selectedTab}
          onChange={handleChangeTab}
          variant="standard"
          centered
          textColor="inherit"
          indicatorColor="primary"
        >
          {tabContent.map((tab, index) => (
            <Tab key={index} label={tab.label} sx={{ fontSize: "large", fontWeight: 900 }} />
          ))}
        </Tabs>
      </Box>
      {tabContent.map((tab, index) => (
        <Typography
          key={index}
          component="div"
          role="tabpanel"
          hidden={selectedTab !== index}
          sx={{ padding: 3 }}
        >
          {selectedTab === index && <Box>{tab.content}</Box>}
        </Typography>
      ))}
    </Container>
  );
}

export default Brand;
