import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Map, MapMarker, MarkerClusterer } from "react-kakao-maps-sdk";
import useKakaoLoader from "../components/useKakaoLoader";
import Pagination from "@mui/material/Pagination";

function Places() {
  const mapRef = useRef();
  const [mapHeight, setMapHeight] = useState(
    window.innerWidth <= 600 ? 500 : window.innerHeight
  );
  const [center, setCenter] = useState({ lat: 37.5780175, lng: 126.862342 });

  const [store, setStore] = useState([]);

  const [page, setPage] = useState(1);
  const eventsPerPage = 5;
  const [selectedCard, setSelectedCard] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/assets/db/place.json`)
      .then((response) => response.json())
      .then((data) => setStore(data.stores));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setMapHeight(400); // 화면 너비가 600px 이하일 때 mapHeight를 500으로 설정
      } else {
        setMapHeight(window.innerHeight - 85); // 그 외의 경우는 화면 높이를 사용
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // 컴포넌트 마운트 시에도 한 번 체크

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  }, []);

  useKakaoLoader();

  const handleSelectCard = (lat, lng) => {
    setCenter({ lat, lng });
    const selectedKey = `${lat}-${lng}`;
    setSelectedCard(selectedKey);

    // Find the index of the store that matches the selected card
    const storeIndex = store.findIndex(
      (storeItem) => `${storeItem.lat}-${storeItem.lng}` === selectedKey
    );

    // Calculate the new page number based on the index
    if (storeIndex >= 0) {
      const newPage = Math.ceil((storeIndex + 1) / eventsPerPage);
      setPage(newPage);
    }
  };
  const handleSelectItem = (lat, lng) => {
    setCenter({ lat, lng });
    const selectedKey = `${lat}-${lng}`;
    setSelectedCard(selectedKey);

    // Find the index of the store that matches the selected card
    const storeIndex = store.findIndex(
      (storeItem) => `${storeItem.lat}-${storeItem.lng}` === selectedKey
    );

    setSearchQuery("");
    // Calculate the new page number based on the index
    if (storeIndex >= 0) {
      const newPage = Math.ceil((storeIndex + 1) / eventsPerPage);
      setPage(newPage);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onClusterclick = (_target, cluster) => {
    const map = mapRef.current;
    // 현재 지도 레벨에서 1레벨 확대한 레벨
    const level = map.getLevel() - 1;

    // 지도를 클릭된 클러스터의 마커의 위치를 기준으로 확대합니다
    map.setLevel(level, { anchor: cluster.getCenter() });
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
    setPage(1); // Reset to first page when search changes
  };
  const filteredStores = store.filter((storeItem) =>
    storeItem.addr_main.toLowerCase().includes(searchQuery)
  );
  const indexOfLastEvent = page * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentStores = filteredStores.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={9} sx={{ mt: 2 }}>
          <Map
            id="map"
            center={center}
            style={{
              width: "100%",
              height: `${mapHeight}px`,
            }}
            level={4}
            ref={mapRef}
          >
            <MarkerClusterer
              averageCenter={true}
              minLevel={6}
              disableClickZoom={true}
              onClusterclick={onClusterclick}
            >
              {store.map((value) => (
                <MapMarker
                  key={`${value.lat}-${value.lng}`}
                  position={{ lat: value.lat, lng: value.lng }}
                  image={{
                    src: `${process.env.PUBLIC_URL}/assets/images/marker/chicken.png`,
                    size: { width: 65, height: 65 },
                    options: { offset: { x: 32, y: 65 } },
                  }}
                  onClick={() => handleSelectItem(value.lat, value.lng)}
                />
              ))}
            </MarkerClusterer>
          </Map>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Box
            sx={{
              textAlign: "center",
              mt: 2,
              bgcolor: "grey.100",
              p: 2,
            }}
          >
            <TextField
              label="관심 주소 검색"
              variant="outlined"
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{
                "& .MuiOutlinedInput-root": { backgroundColor: "white" },
                mb: 2,
              }}
            />
            {currentStores.map((value) => (
              <Card
                key={`${value.lat}-${value.lng}`}
                sx={{
                  mt: 0.5,
                  mb: 0.5,
                  cursor: "pointer",
                  backgroundColor:
                    selectedCard === `${value.lat}-${value.lng}`
                      ? "lightgrey"
                      : "white",
                }}
                onClick={() => handleSelectCard(value.lat, value.lng)}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 900 }}
                  >
                    {value.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5, fontWeight: 500 }}>
                    {value.addr_main} <br /> {value.addr_sub}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 0.5, fontWeight: 500 }}>
                    {value.phone}
                  </Typography>
                </CardContent>
              </Card>
            ))}
            <Pagination
              count={Math.ceil(filteredStores.length / eventsPerPage)}
              page={page}
              onChange={handlePageChange}
              sx={{ marginY: 2, display: "flex", justifyContent: "center" }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Places;
